import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from './verified.json';
import ReCAPTCHA from 'react-google-recaptcha';
import { getCertificateDetails } from '../utils/googleSheet';
import './VerifyForm.css'; // Import the CSS file for styling
// https://cert.neemiracle.tech?certificateId=iaRjocer7A

const VerifyForm = () => {
  const recaptchaRef = useRef(null);
  const [certificateId, setCertificateId] = useState('');
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [error, setError] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');

  // Function to get URL parameter by name
  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  useEffect(() => {
    // Set certificateId from URL if available
    const idFromUrl = getUrlParameter('certificateId');
    if (idFromUrl) {
      setCertificateId(idFromUrl);
    }
  }, []);

  const validateInput = (id) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10}$/;
    return regex.test(id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!captchaToken) {
      setCertificateDetails(null);
      setError('Please complete the CAPTCHA');
      return;
    }

    if (!validateInput(certificateId)) {
      setCertificateDetails(null);
      setError('Certificate ID must be exactly 10 characters long, and include both letters and numbers.');
      return;
    }

    // Fetch certificate details
    const details = await getCertificateDetails(certificateId);

    // Handle response
    if (details) {
      setCertificateDetails(details);
      setError('');
    } else {
      setCertificateDetails(null);
      setError('No certificate found');
    }

    // Reset the captcha token and widget
    setCaptchaToken('');
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  const defaultOptions = {
    loop: false,  // Play only once
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="verify-form">
      <div className="title-bar">
        <img src="/Neemiracle-internship-DB.png" alt="Logo" className="logo" /> 
      </div>
      <form onSubmit={handleSubmit} className="form">
        <input
          type="text"
          value={certificateId}
          onChange={(e) => setCertificateId(e.target.value)}
          placeholder="Enter Certificate ID"
          title="Enter Certificate ID"
          required
          className="input-box"
        />
        <div className="recaptcha-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={setCaptchaToken}
            className="recaptcha"
          />
        </div>
        <div className='button-center'>
          <button type="submit" className="btn">
            <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
              <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
            </svg>
            <span className="text">Verify</span>
          </button>
        </div>
      </form>
      {error && <div className="error-box">{error}</div>}
      {certificateDetails && (
        <div className="frame">
          <div className="details-table-container">
            <div className="details-header">
              <h2>Certificate Details</h2>
              <Lottie options={defaultOptions} height={50} width={50} className="lottie-animation" />
            </div>
            <table className="details-table">
              <tbody>
                <tr>
                  <th>Intern ID</th>
                  <td>{certificateDetails[1]}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{certificateDetails[2]}</td>
                </tr>
                <tr>
                  <th>Domain</th>
                  <td>{certificateDetails[3]}</td>
                </tr>
                <tr>
                  <th>Project Work</th>

                  <td><a href={certificateDetails[4]} referrerpolicy="no-referrer" target="_blank" className='projectLink'>Link</a></td>
                </tr>
                <tr>
                  <th>Batch ID</th>
                  <td>{certificateDetails[5]}</td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>{certificateDetails[6]}</td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>{certificateDetails[7]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <footer className="footer">
        <div className="icons">
          <a href="https://www.linkedin.com/company/neemiracle-tech" className="ln" referrerpolicy="no-referrer" target="_blank"><i className="fab fa-linkedin-in"></i></a>
          <a href="https://www.instagram.com/neemiracle" className="insta" referrerpolicy="no-referrer" target="_blank"><i className="fab fa-instagram"></i></a>
          <a href="https://www.youtube.com/@neemiracle-tech" className="yt" referrerpolicy="no-referrer" target="_blank"><i className="fab fa-youtube"></i></a>
        </div>
      </footer>
    </div>
  );
};

export default VerifyForm;
