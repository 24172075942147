import React from 'react';
import VerifyForm from '../components/VerifyForm';

const Index = () => (
  <div>
    <VerifyForm />
  </div>
);

export default Index;
