import axios from 'axios';

const SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const getCertificateDetails = async (certificateId) => {
  const range = 'DATABASE!A:H'; // Adjust range if necessary
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${range}?key=${API_KEY}`;
  
  try {
    const response = await axios.get(url);
    const rows = response.data.values;
    // Skip header row
    const certificate = rows.slice(1).find(row => row[0] === certificateId);
    return certificate || null;
  } catch (error) {
    console.error('Error fetching data from Database:', error);
    return null;
  }
};
